import React from 'react';
import { Container } from 'react-bootstrap';
import { animateScroll } from 'react-scroll';

export default function Footer() {
  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <i
            className="fa fa-angle-up fa-2x"
            aria-hidden="true"
            onClick={() => animateScroll.scrollToTop()}
          />
        </span>
        <p className="footer__text">© {new Date().getFullYear()} - ULTRASANO dr. Saghir j.d.o.o</p>
        {/* <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || 'https://github.com/cobidev/gatsby-simplefolio'}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}
        </div> */}
      </Container>
    </footer>
  );
}
